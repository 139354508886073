const Pythonds = () => {
  return (
    <div className="data-b flex gap-2 my-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 256 256">
        <g fill="none">
          <rect width="256" height="256" fill="#242938" rx="60" />
          <path
            fill="url(#skillIconsPythonDark0)"
            d="M127.279 29c-50.772 0-47.602 22.018-47.602 22.018l.057 22.81h48.451v6.85H60.489S28 76.992 28 128.221c0 51.23 28.357 49.414 28.357 49.414h16.924v-23.773s-.912-28.357 27.905-28.357h48.054s26.999.436 26.999-26.094V55.546S180.338 29 127.279 29m-26.716 15.339a8.708 8.708 0 0 1 8.717 8.717a8.708 8.708 0 0 1-8.717 8.716a8.708 8.708 0 0 1-8.716-8.716a8.708 8.708 0 0 1 8.716-8.717"
          />
          <path
            fill="url(#skillIconsPythonDark1)"
            d="M128.721 227.958c50.772 0 47.602-22.017 47.602-22.017l-.057-22.811h-48.451v-6.849h67.696S228 179.966 228 128.736c0-51.23-28.357-49.413-28.357-49.413h-16.924v23.773s.912 28.357-27.905 28.357H106.76s-27-.437-27 26.093v43.866s-4.099 26.546 48.961 26.546m26.716-15.339a8.708 8.708 0 0 1-8.717-8.716a8.708 8.708 0 0 1 8.717-8.717a8.709 8.709 0 0 1 8.717 8.717a8.709 8.709 0 0 1-8.717 8.716"
          />
          <defs>
            <linearGradient
              id="skillIconsPythonDark0"
              x1="47.22"
              x2="146.333"
              y1="46.896"
              y2="145.02"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#387eb8" />
              <stop offset="1" stop-color="#366994" />
            </linearGradient>
            <linearGradient
              id="skillIconsPythonDark1"
              x1="108.056"
              x2="214.492"
              y1="109.905"
              y2="210.522"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#ffe052" />
              <stop offset="1" stop-color="#ffc331" />
            </linearGradient>
          </defs>
        </g>
      </svg>
    </div>
  );
};

export default Pythonds;
