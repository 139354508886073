const IconsSoft = () => {
  return (
    <div className="icons-b flex gap-2 my-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 256 256">
        <g fill="none">
          <rect width="256" height="256" fill="#242938" rx="60" />
          <path
            fill="#fff"
            d="M49.158 100.246h28.408c8.338.07 14.38 2.474 18.126 7.208c3.746 4.734 4.982 11.2 3.71 19.398c-.494 3.746-1.59 7.42-3.286 11.024c-1.626 3.604-3.886 6.854-6.784 9.752c-3.534 3.674-7.314 6.006-11.342 6.996c-4.028.99-8.198 1.484-12.508 1.484h-12.72l-4.028 20.14H34zM61.56 112.33l-6.36 31.8c.424.07.848.106 1.272.106h1.484c6.784.07 12.438-.6 16.96-2.014c4.522-1.484 7.562-6.642 9.116-15.476c1.272-7.42 0-11.696-3.816-12.826c-3.746-1.13-8.444-1.66-14.098-1.59c-.848.07-1.66.106-2.438.106h-2.226zM116.186 80h14.628l-4.134 20.246h13.144c7.208.142 12.578 1.626 16.112 4.452c3.604 2.826 4.664 8.198 3.18 16.112l-7.102 35.298h-14.84l6.784-33.708c.706-3.534.494-6.042-.636-7.526s-3.568-2.226-7.314-2.226l-11.766-.106l-8.692 43.566h-14.628zm58.638 20.246h28.408c8.338.07 14.38 2.474 18.126 7.208c3.746 4.734 4.982 11.2 3.71 19.398c-.494 3.746-1.59 7.42-3.286 11.024c-1.626 3.604-3.886 6.854-6.784 9.752c-3.534 3.674-7.314 6.006-11.342 6.996c-4.028.99-8.198 1.484-12.508 1.484h-12.72l-4.028 20.14h-14.734zm12.402 12.084l-6.36 31.8c.424.07.848.106 1.272.106h1.484c6.784.07 12.438-.6 16.96-2.014c4.522-1.484 7.562-6.642 9.116-15.476c1.272-7.42 0-11.696-3.816-12.826c-3.746-1.13-8.444-1.66-14.098-1.59c-.848.07-1.66.106-2.438.106h-2.226z"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 256 256">
        <g fill="none">
          <rect width="256" height="256" fill="#242938" rx="60" />
          <path
            fill="#81cd39"
            d="M119.878 31.116c4.919-2.815 11.325-2.828 16.239 0c24.722 13.97 49.452 27.917 74.17 41.895c4.65 2.619 7.759 7.793 7.712 13.15v84.045c.035 5.579-3.382 10.877-8.287 13.436c-24.641 13.893-49.27 27.802-73.907 41.695c-5.019 2.87-11.554 2.649-16.418-.457c-7.387-4.282-14.787-8.544-22.175-12.822c-1.51-.9-3.212-1.616-4.278-3.08c.943-1.27 2.628-1.428 3.997-1.983c3.083-.981 5.916-2.555 8.748-4.082c.717-.49 1.591-.302 2.278.136c6.317 3.622 12.579 7.35 18.917 10.937c1.352.781 2.721-.256 3.877-.9c24.18-13.667 48.39-27.281 72.567-40.952c.896-.431 1.391-1.382 1.318-2.363c.017-27.725.004-55.454.009-83.18c.102-1.112-.542-2.136-1.549-2.592c-24.555-13.829-49.099-27.678-73.65-41.51a2.557 2.557 0 0 0-2.892-.005c-24.552 13.837-49.09 27.7-73.642 41.527c-1.003.457-1.676 1.464-1.557 2.58c.005 27.726 0 55.455 0 83.184a2.352 2.352 0 0 0 1.336 2.334c6.551 3.715 13.111 7.404 19.667 11.107c3.694 1.987 8.228 3.169 12.298 1.646c3.59-1.288 6.107-4.953 6.039-8.765c.034-27.563-.017-55.13.025-82.69c-.09-1.223 1.071-2.234 2.261-2.118c3.148-.022 6.3-.043 9.448.008c1.314-.03 2.218 1.288 2.056 2.52c-.013 27.738.034 55.476-.021 83.213c.008 7.393-3.029 15.437-9.867 19.054c-8.423 4.363-18.835 3.438-27.157-.746c-7.204-3.596-14.08-7.84-21.156-11.692c-4.918-2.545-8.318-7.864-8.283-13.439V86.161c-.052-5.468 3.182-10.736 7.975-13.317c24.637-13.903 49.27-27.818 73.902-41.728"
          />
          <path
            fill="#81cd39"
            d="M141.372 89.335c10.745-.692 22.248-.41 31.917 4.884c7.487 4.056 11.637 12.57 11.769 20.887c-.209 1.121-1.382 1.74-2.453 1.663c-3.117-.004-6.236.043-9.353-.021c-1.323.051-2.091-1.168-2.257-2.337c-.896-3.98-3.067-7.921-6.812-9.841c-5.75-2.878-12.416-2.733-18.686-2.673c-4.577.242-9.499.639-13.377 3.33c-2.977 2.039-3.881 6.155-2.819 9.47c1.002 2.38 3.749 3.148 5.998 3.856c12.949 3.387 26.671 3.049 39.373 7.506c5.259 1.817 10.403 5.35 12.203 10.856c2.355 7.38 1.323 16.2-3.928 22.124c-4.258 4.875-10.459 7.529-16.644 8.97c-8.228 1.835-16.767 1.882-25.123 1.067c-7.857-.896-16.034-2.96-22.099-8.313c-5.187-4.504-7.72-11.522-7.469-18.294c.06-1.144 1.199-1.942 2.295-1.848c3.139-.025 6.279-.034 9.418.005c1.255-.09 2.184.994 2.249 2.176c.578 3.791 2.003 7.771 5.31 10.018c6.38 4.117 14.387 3.835 21.693 3.95c6.053-.268 12.848-.349 17.787-4.35c2.606-2.282 3.378-6.1 2.674-9.384c-.763-2.773-3.664-4.065-6.155-4.91c-12.783-4.043-26.659-2.576-39.318-7.149c-5.14-1.816-10.11-5.25-12.084-10.53c-2.755-7.473-1.493-16.717 4.308-22.44c5.656-5.695 13.82-7.888 21.583-8.672"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 256 256">
        <g fill="none">
          <rect width="256" height="256" fill="#242938" rx="60" />
          <path
            fill="url(#skillIconsPythonDark0)"
            d="M127.279 29c-50.772 0-47.602 22.018-47.602 22.018l.057 22.81h48.451v6.85H60.489S28 76.992 28 128.221c0 51.23 28.357 49.414 28.357 49.414h16.924v-23.773s-.912-28.357 27.905-28.357h48.054s26.999.436 26.999-26.094V55.546S180.338 29 127.279 29m-26.716 15.339a8.708 8.708 0 0 1 8.717 8.717a8.708 8.708 0 0 1-8.717 8.716a8.708 8.708 0 0 1-8.716-8.716a8.708 8.708 0 0 1 8.716-8.717"
          />
          <path
            fill="url(#skillIconsPythonDark1)"
            d="M128.721 227.958c50.772 0 47.602-22.017 47.602-22.017l-.057-22.811h-48.451v-6.849h67.696S228 179.966 228 128.736c0-51.23-28.357-49.413-28.357-49.413h-16.924v23.773s.912 28.357-27.905 28.357H106.76s-27-.437-27 26.093v43.866s-4.099 26.546 48.961 26.546m26.716-15.339a8.708 8.708 0 0 1-8.717-8.716a8.708 8.708 0 0 1 8.717-8.717a8.709 8.709 0 0 1 8.717 8.717a8.709 8.709 0 0 1-8.717 8.716"
          />
          <defs>
            <linearGradient
              id="skillIconsPythonDark0"
              x1="47.22"
              x2="146.333"
              y1="46.896"
              y2="145.02"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#387eb8" />
              <stop offset="1" stop-color="#366994" />
            </linearGradient>
            <linearGradient
              id="skillIconsPythonDark1"
              x1="108.056"
              x2="214.492"
              y1="109.905"
              y2="210.522"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#ffe052" />
              <stop offset="1" stop-color="#ffc331" />
            </linearGradient>
          </defs>
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 256 256">
        <g fill="none">
          <rect width="256" height="256" fill="#e14e1d" rx="60" />
          <path
            fill="#fff"
            d="m48 38l8.61 96.593h110.71l-3.715 41.43l-35.646 9.638l-35.579-9.624l-2.379-26.602H57.94l4.585 51.281l65.427 18.172l65.51-18.172l8.783-98.061H85.824l-2.923-32.71h122.238L208 38z"
          />
          <path
            fill="#ebebeb"
            d="M128 38H48l8.61 96.593H128v-31.938H85.824l-2.923-32.71H128zm0 147.647l-.041.014l-35.579-9.624l-2.379-26.602H57.94l4.585 51.281l65.427 18.172l.049-.014z"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 256 256">
        <g fill="none">
          <rect width="256" height="256" fill="#0277bd" rx="60" />
          <path
            fill="#ebebeb"
            d="m53.753 102.651l2.862 31.942h71.481v-31.942zM128.095 38H48l2.904 31.942h77.191zm0 180.841v-33.233l-.14.037l-35.574-9.605l-2.274-25.476H58.042l4.475 50.154l65.431 18.164z"
          />
          <path
            fill="#fff"
            d="m167.318 134.593l-3.708 41.426l-35.625 9.616v33.231l65.483-18.148l.48-5.397l7.506-84.092l.779-8.578L208 38h-80.015v31.942h45.009l-2.906 32.709h-42.103v31.942z"
          />
        </g>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 256 256">
        <g fill="none">
          <rect width="256" height="256" fill="#f0db4f" rx="60" />
          <path
            fill="#323330"
            d="m67.312 213.932l19.59-11.856c3.78 6.701 7.218 12.371 15.465 12.371c7.905 0 12.889-3.092 12.889-15.12v-81.798h24.058v82.138c0 24.917-14.606 36.259-35.916 36.259c-19.245 0-30.416-9.967-36.087-21.996m85.07-2.576l19.588-11.341c5.157 8.421 11.859 14.607 23.715 14.607c9.969 0 16.325-4.984 16.325-11.858c0-8.248-6.53-11.17-17.528-15.98l-6.013-2.579c-17.357-7.388-28.871-16.668-28.871-36.258c0-18.044 13.748-31.792 35.229-31.792c15.294 0 26.292 5.328 34.196 19.247l-18.731 12.029c-4.125-7.389-8.591-10.31-15.465-10.31c-7.046 0-11.514 4.468-11.514 10.31c0 7.217 4.468 10.139 14.778 14.608l6.014 2.577c20.449 8.765 31.963 17.699 31.963 37.804c0 21.654-17.012 33.51-39.867 33.51c-22.339 0-36.774-10.654-43.819-24.574"
          />
        </g>
      </svg>
    </div>
  );
};

export default IconsSoft;
