import { Link } from "react-router-dom";
import IntroPic from "../../assets/grad4.jpg";
import GlobLearning from "../../assets/globlearning.png";
import Growth from "../../assets/growth.png";
import Certification from "../../assets/certification.png";
import ProfessionalNetwork from "../../assets/professionalnetwork.png";
import GlobalEmployment from "../../assets/globalemployment.png";
import SalaryIncrease from "../../assets/salaryincrease.png";
import BusinessCredibility from "../../assets/businesscredibility.png";
import CanadaFlyer from "../../assets/flyer1.png"
import Icons from "../Icons/Fend";
import IconsF from "../Icons/Fstack";
import DataB from "../Icons/DataB";
import Dsci from "../Icons/Dsci";
import Cyber from "../Icons/Cyber";
import Pythonds from "../Icons/Pythonds";
import ProdD from "../Icons/ProdD";
import IconsSoft from "../Icons/Sicon";

const SkillDCC = () => {
  return (
    <>
      <div
        id="hero"
        className="Sdcc-bg bg-no-repeat bg-center bg-cover h-[80vh]"
      >
        <div className="flex flex-col justify-center items-center h-[80vh]">
          <div className="flex flex-col justify-center items-center animate-slideDown">
            <h2 className="text-white md:text-[50px] font-[600] sm:text-[40px] text-[30px] capitalize text-center sm:w-[70%] w-[90%]">
              Go international with <b>Babtech Computers</b> and{" "}
              <b>Skills Development Council Canada</b>
            </h2>
            <Link
              to='/diploma'
              className="text-white text-center text-[15px] mt-[14px] hover:bg-[#1565C0] transition-all ease-in-out uppercase font-[700] rounded-[5px] bg-[#000066] px-[30px] py-[10px]"
            >
              EXPLORE
            </Link>
          </div>
        </div>
      </div>

      <div className="my-[50px]">
        <h2 className="text-[#000066] text-[35px] text-center font-[700] mb-[30px]">
          Introduction
        </h2>
        <div className="lg:grid lg:grid-cols-2 grid grid-cols-1 sm:gap-[50px] gap-[20px] items-center sm:px-[60px] px-[20px]">
          <div>
            <p className="font-[500] md:text-[25px] text-[20px] lg:text-start text-center">
              Making impact for 10 years, we excited to announce that we have{" "}
              <b>
                officially received accreditation in 10 of our courses including
                3 Advanced Diploma Courses from the Skills Development Council
                of Canada, recognizing us as a Learning Partner.
              </b>{" "}
              Moving forward, our certificates will be issued by the Council,
              empowering our graduates with globally recognized certificate and
              positioning them as certified tech professionals on the
              international level.
            </p>
          </div>
          <div>
            <img src={CanadaFlyer} alt="Intro Img" />
          </div>
        </div>
      </div>

      <div className="mt-[150px] mb-[50px]">
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-[#000066] text-[35px] text-center font-[700]">
            The Value of our Certification
          </h2>
          <h3 className="text-[#8A0307] text-[20px] text-center font-[700] uppercase">
            why our certification matters
          </h3>
        </div>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px] sm:px-[60px] px-[20px] mt-[50px]">
          <div className="bg-[white] w-[100%] rounded-[5px] px-[15px] py-[10px] shadow-lg">
            <div className="flex items-center gap-[5px]">
              <img
                src={GlobLearning}
                alt="Global Learning Icon"
                className="h-[10vh]"
              />
              <h3 className="font-[700] text-[17px]">Global Recognition</h3>
            </div>
            <div>
              <p>
                Our certification is recognized worldwide, giving you
                credibility and opening doors to international career
                opportunities.
              </p>
            </div>
          </div>
          <div className="bg-[white] w-[100%] rounded-[5px] px-[15px] py-[10px] shadow-lg">
            <div className="flex items-center gap-[5px]">
              <img
                src={Certification}
                alt="Global Learning Icon"
                className="h-[10vh]"
              />
              <h3 className="font-[700] text-[17px]">Competitive Advantage</h3>
            </div>
            <div>
              <p>
                Stand out to employers with a certification that sets you apart
                as a highly qualified and certified professional.
              </p>
            </div>
          </div>
          <div className="bg-[white] w-[100%] rounded-[5px] px-[15px] py-[10px] shadow-lg">
            <div className="flex items-center gap-[5px]">
              <img
                src={Growth}
                alt="Global Learning Icon"
                className="h-[10vh]"
              />
              <h3 className="font-[700] text-[17px]">Career Growth</h3>
            </div>
            <div>
              <p>
                Our certification is a catalyst for career advancement,
                increasing your chances of landing higher-paying roles and
                leadership positions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[150px] mb-[50px]">
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-[#000066] text-[35px] text-center font-[700]">
            Opportunities Unlocked
          </h2>
          <h3 className="text-[#8A0307] text-[20px] text-center font-[700] uppercase">
            opportunities await you
          </h3>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px] sm:px-[60px] px-[20px] mt-[50px]">
          <div className="bg-[white] w-[100%] rounded-[5px] px-[15px] py-[10px] shadow-lg">
            <div className="flex justify-center items-center">
            <img
                src={ProfessionalNetwork}
                alt="Global Learning Icon"
                className="h-[10vh]"
              />
            </div>
            <div className="flex flex-col justify-center items-center mt-[5px]">
            <h3 className="font-[700] text-[17px] text-center">Professional Networks</h3>
              <p className="text-center">
                The certification connects you with a global network of
                professionals and industry leaders, offering valuable networking
                opportunities.
              </p>
            </div>
          </div>
          <div className="bg-[white] w-[100%] rounded-[5px] px-[15px] py-[10px] shadow-lg">
            <div className="flex justify-center items-center">
              <img
                src={GlobalEmployment}
                alt="Global Learning Icon"
                className="h-[10vh]"
              />
              
            </div>
            <div className="flex flex-col justify-center items-center mt-[5px]">
            <h3 className="font-[700] text-[17px] text-center">
                Global Employment/Remote Jobs
              </h3>
              <p className="text-center">
                The SDC Certificate is recognized internationally, enabling you
                to pursue remote job opportunities in various countries and
                global companies.
              </p>
            </div>
          </div>
          <div className="bg-[white] w-[100%] rounded-[5px] px-[15px] py-[10px] shadow-lg">
            <div className="flex justify-center items-center">
              <img
                src={SalaryIncrease}
                alt="Global Learning Icon"
                className="h-[10vh]"
              />
             
            </div>
            <div className="flex flex-col justify-center items-center mt-[5px]">
            <h3 className="font-[700] text-[17px] text-center">Salary Increase</h3>
              <p className="text-center">
                Certified professionals often command higher salaries due to
                their verified skills and expertise.
              </p>
            </div>
          </div>
          <div className="bg-[white] w-[100%] rounded-[5px] px-[15px] py-[10px] shadow-lg">
            <div className="flex justify-center items-center">
              <img
                src={BusinessCredibility}
                alt="Global Learning Icon"
                className="h-[10vh]"
              />
             
            </div>
            <div className="flex flex-col justify-center items-center mt-[5px]">
            <h3 className="font-[700] text-[17px] text-center">Business Credibility</h3>
              <p className="text-center">
                If you're an entrepreneur, the SDC Certificate adds credibility
                to your business, making it easier to attract clients and
                investors.
              </p>
            </div>
          </div>
        </div>
      </div>

     

      <div className="bg-[#000066] h-[40vh]">
        <div className="flex flex-col h-[40vh] justify-center items-center">
            <h2 className="md:text-[50px] text-[40px] capitalize font-[700] text-white text-center">start your journey today!</h2>
            <Link to="/Admission" className="uppercase text-[15px] bg-white rounded-[4px] text-[#000066] font-[700] px-[20px] py-[10px] hover:bg-transparent hover:border-[1px] hover:border-[white] hover:text-white transition-all ease-in-out mt-[14px]">Apply now</Link>
        </div>
      </div>
    </>
  );
};
export default SkillDCC;
