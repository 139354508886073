import SdccLogo from "../../assets/SDCC LOGO.jpg";
import { Link } from "react-router-dom";
const Sdcc = () => {
  return (
    <>
      <div className="mt-[50px]">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center md:gap-[30px] gap-[10px] px-[10px]">
          <div className="w-[100%]">
            <img src={SdccLogo} />
          </div>
          <div className=" w-[100%] flex md:justify-start justify-center md:items-start items-center flex-col">
            <p className="font-Raleway text-[26px] font-[500] leading-10 md:text-start text-center mb-[20px]">
              Unlock global opportunities with our expert training, mentorship,
              job support and SDC Canada certification. Learn from us, recieve
              Certifcate from Canada.
            </p>
            <Link
              to='/skilldevelopment'
              className="text-white text-center text-[15px] hover:bg-[#1565C0] transition-all ease-in-out uppercase font-[700] rounded-[5px] bg-[#000066] px-[30px] py-[10px]"
            >
              READ MORE
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sdcc;
