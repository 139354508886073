import SkillDCC from "../../components/Sdcc/SkillDevelopment";
import Navbar from "../../components/Navbar/Index";
import NavFooter from "../../components/Footer/Footer";
const SkillDevelopment = () => {
    return (
        <>
            <Navbar/>
            <SkillDCC />
            <NavFooter />
        </>
    )
}

export default SkillDevelopment;