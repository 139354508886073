import cyberImg from "../../assets/Burp-Suite-Professional.png";
import cyberImg2 from "../../assets/idHH5r6sQ6_1715162814518.png";
import cyberImg3 from "../../assets/idcyfG2WAy_1715162781536.png";
import cyberImg4 from "../../assets/idshZQDFmY_logos.jpeg";

const Cyber = () => {
  return (
    <div className="data-b flex gap-2 my-2">
      <img
        src={cyberImg}
        alt="Cyber Security"
        className="h-[1em] rounded-[2px]"
      />
      <img
        src={cyberImg2}
        alt="Cyber Security"
        className="h-[1em] rounded-[2px]"
      />
      <img
        src={cyberImg3}
        alt="Cyber Security"
        className="h-[1em] rounded-[2px]"
      />
      <img
        src={cyberImg4}
        alt="Cyber Security"
        className="h-[1em] rounded-[2px]"
      />

      {/* <svg xmlns="http://www.w3.org/2000/svg" width="0.75em" height="1em" viewBox="0 0 256 342"><defs><linearGradient id="logosMicrosoftPowerBi0" x1="42.593%" x2="57.245%" y1="0%" y2="93.174%"><stop offset="0%" stop-color="#e6ad10"/><stop offset="100%" stop-color="#c87e0e"/></linearGradient><linearGradient id="logosMicrosoftPowerBi1" x1="40.122%" x2="62.028%" y1="0%" y2="95.592%"><stop offset="0%" stop-color="#f6d751"/><stop offset="100%" stop-color="#e6ad10"/></linearGradient><linearGradient id="logosMicrosoftPowerBi2" x1="27.774%" x2="55.012%" y1="0%" y2="96.828%"><stop offset="0%" stop-color="#f9e589"/><stop offset="100%" stop-color="#f6d751"/></linearGradient><path id="logosMicrosoftPowerBi3" d="M142.222 14.222C142.222 6.368 148.59 0 156.444 0h85.334C249.632 0 256 6.368 256 14.222v312.89c0 7.854-6.368 14.221-14.222 14.221H14.222C6.368 341.333 0 334.966 0 327.111V184.89c0-7.855 6.368-14.222 14.222-14.222h56.89V99.556c0-7.855 6.367-14.223 14.222-14.223h56.888z"/></defs><mask id="logosMicrosoftPowerBi4" fill="#fff"><use href="#logosMicrosoftPowerBi3"/></mask><path fill="url(#logosMicrosoftPowerBi0)" d="M256 0v341.333H142.222V0z" mask="url(#logosMicrosoftPowerBi4)"/><path fill-opacity="0.2" d="M184.889 100.978v241.778H71.11v-256h99.556c7.854 0 14.222 6.367 14.222 14.222" mask="url(#logosMicrosoftPowerBi4)"/><path fill-opacity="0.18" d="M184.889 106.667v241.777H71.11v-256h99.556c7.854 0 14.222 6.368 14.222 14.223" mask="url(#logosMicrosoftPowerBi4)"/><path fill="url(#logosMicrosoftPowerBi1)" d="M184.889 99.556v241.777H71.11v-256h99.556c7.854 0 14.222 6.368 14.222 14.223" mask="url(#logosMicrosoftPowerBi4)"/><path fill="url(#logosMicrosoftPowerBi2)" d="M0 170.667v170.666h113.778V184.89c0-7.855-6.368-14.222-14.222-14.222z" mask="url(#logosMicrosoftPowerBi4)"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><path fill="#428eff" d="M8.562 15.256A21.159 21.159 0 0 0 16 16.449a21.159 21.159 0 0 0 7.438-1.194c1.864-.727 2.525-1.535 2.525-2V9.7a10.357 10.357 0 0 1-2.084 1.076A22.293 22.293 0 0 1 16 12.078a22.36 22.36 0 0 1-7.879-1.3A10.28 10.28 0 0 1 6.037 9.7v3.55c0 .474.663 1.278 2.525 2.006m0 6.705a15.611 15.611 0 0 0 2.6.741a24.9 24.9 0 0 0 4.838.453a24.9 24.9 0 0 0 4.838-.452a15.614 15.614 0 0 0 2.6-.741c1.864-.727 2.525-1.535 2.525-2v-3.39a10.706 10.706 0 0 1-1.692.825A23.49 23.49 0 0 1 16 18.74a23.49 23.49 0 0 1-8.271-1.348a10.829 10.829 0 0 1-1.692-.825v3.393c0 .466.663 1.271 2.525 2.001M16 30c5.5 0 9.963-1.744 9.963-3.894v-2.837a10.5 10.5 0 0 1-1.535.762l-.157.063A23.487 23.487 0 0 1 16 25.445a23.422 23.422 0 0 1-8.271-1.351c-.054-.02-.106-.043-.157-.063a10.5 10.5 0 0 1-1.535-.762v2.837C6.037 28.256 10.5 30 16 30"/><ellipse cx="16" cy="5.894" fill="#428eff" rx="9.963" ry="3.894"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><g fill="none"><rect width="256" height="256" fill="#242938" rx="60"/><path fill="url(#skillIconsPythonDark0)" d="M127.279 29c-50.772 0-47.602 22.018-47.602 22.018l.057 22.81h48.451v6.85H60.489S28 76.992 28 128.221c0 51.23 28.357 49.414 28.357 49.414h16.924v-23.773s-.912-28.357 27.905-28.357h48.054s26.999.436 26.999-26.094V55.546S180.338 29 127.279 29m-26.716 15.339a8.708 8.708 0 0 1 8.717 8.717a8.708 8.708 0 0 1-8.717 8.716a8.708 8.708 0 0 1-8.716-8.716a8.708 8.708 0 0 1 8.716-8.717"/><path fill="url(#skillIconsPythonDark1)" d="M128.721 227.958c50.772 0 47.602-22.017 47.602-22.017l-.057-22.811h-48.451v-6.849h67.696S228 179.966 228 128.736c0-51.23-28.357-49.413-28.357-49.413h-16.924v23.773s.912 28.357-27.905 28.357H106.76s-27-.437-27 26.093v43.866s-4.099 26.546 48.961 26.546m26.716-15.339a8.708 8.708 0 0 1-8.717-8.716a8.708 8.708 0 0 1 8.717-8.717a8.709 8.709 0 0 1 8.717 8.717a8.709 8.709 0 0 1-8.717 8.716"/><defs><linearGradient id="skillIconsPythonDark0" x1="47.22" x2="146.333" y1="46.896" y2="145.02" gradientUnits="userSpaceOnUse"><stop stop-color="#387eb8"/><stop offset="1" stop-color="#366994"/></linearGradient><linearGradient id="skillIconsPythonDark1" x1="108.056" x2="214.492" y1="109.905" y2="210.522" gradientUnits="userSpaceOnUse"><stop stop-color="#ffe052"/><stop offset="1" stop-color="#ffc331"/></linearGradient></defs></g></svg> */}
    </div>
  );
};

export default Cyber;
